import React from 'react'

const Footer = () => {
    return (
        <div>
            <footer>
                <p className='footer-text'>Jesse Thomas Hoffmann</p>
                <br />
                <p className='footer-text-three'>Software Engineer</p>
            </footer>
        </div>
    )
}

export default Footer
